import { gql } from '@apollo/client/core';
import {
  FRAGMENT_AUTH,
  FRAGMENT_CLIENT,
  FRAGMENT_CLIENT_ADMIN,
  FRAGMENT_COMPANY,
  FRAGMENT_COMPLAINT,
  FRAGMENT_COMPLAINT_DOCUMENT,
  FRAGMENT_COMPANY_RELATION,
  FRAGMENT_FRACHT_USER_TYPE,
  FRAGMENT_INVOICE_DATA_OUTPUT,
  FRAGMENT_MY_LOADS_DATA_OUTPUT,
  FRAGMENT_ORDER_DATA_OUTPUT,
  FRAGMENT_AIR_TRANSPORT_ORDER,
  FRAGMENT_AIR_TRANSPORT_ORDER_RATE,
  FRAGMENT_NEWSLETTER_TYPE,
  FRAGMENT_AIR_SEA_TRANSPORTER,
  FRAGMENT_BANKING_DETAIL,
  FRAGMENT_INVOICE,
  FRAGMENT_BILLING,
  FRAGMENT_CONTAINER,
  FRAGMENT_SHIPMENT,
  FRAGMENT_SHIPMENT_DOCUMENT,
  FRAGMENT_FRACHT_BRANCH_MEMBER_TYPE,
  FRAGMENT_FRACHT_BRANCH_TYPE,
  FRAGMENT_FRACHT_AGENT_OFFICE_TYPE,
  FRAGMENT_FRACHT_OFFICE_AGENT_CONTACT_TYPE, FRAGMENT_DEAL,
} from './fragments';

export const MUTATION_LOGIN = gql`
  mutation login($email: String!, $pass: String!, $pageType: pageTypeEnumGQL) {
    login(email: $email, pass: $pass, pageType: $pageType) {
      token
      exp
      userId
      refreshToken
      expRefresh
    }
  }
`;

export const MUTATION_REFRESH_TOKEN = gql`
  ${FRAGMENT_AUTH}
  mutation refreshToken($refreshToken: String) {
    refreshToken(refreshToken: $refreshToken) {
      ...FragmentAuth
    }
  }
`;

export const MUTATION_LOGIN_AS = gql`
  ${FRAGMENT_AUTH}
  mutation loginAs($input: String!) {
    loginAs(input: $input) {
      ...FragmentAuth
    }
  }
`;

export const MUTATION_UPSERT_ORDER = gql`
  ${FRAGMENT_ORDER_DATA_OUTPUT}
  mutation createOrder($data: OrderDataInput) {
    createOrder(data: $data) {
      ...OrderDataOutputFragment
    }
  }
`;

export const MUTATION_CREATE_FRACHTUSER = gql`
  ${FRAGMENT_FRACHT_USER_TYPE}
  mutation createFrachtUser($frachtUserData: FrachtUserInput!) {
    createFrachtUser(frachtUserData: $frachtUserData) {
      ...FragmentFrachtUserType
    }
  }
`;

export const MUTATION_CREATE_BRANCH_MEMBER = gql`
  ${FRAGMENT_FRACHT_BRANCH_MEMBER_TYPE}
  mutation createFrachtBranchMember(
    $frachtBranchMemberData: FrachtBranchMemberInput!
  ) {
    createFrachtBranchMember(frachtBranchMemberData: $frachtBranchMemberData) {
      ...FragmentFrachtBranchMemberType
    }
  }
`;

export const MUTATION_CREATE_BRANCH = gql`
  ${FRAGMENT_FRACHT_BRANCH_TYPE}
  mutation createFrachtBranch($frachtBranchData: FrachtBranchInput!) {
    createFrachtBranch(frachtBranchData: $frachtBranchData) {
      ...FragmentFrachtBranchType
    }
  }
`;

export const MUTATION_EDIT_BRANCH_MEMBER = gql`
  ${FRAGMENT_FRACHT_BRANCH_MEMBER_TYPE}
  mutation editFrachtBranchMember(
    $frachtBranchMemberData: FrachtBranchMemberInput!
  ) {
    editFrachtBranchMember(frachtBranchMemberData: $frachtBranchMemberData) {
      ...FragmentFrachtBranchMemberType
    }
  }
`;

export const MUTATION_EDIT_BRANCH = gql`
  ${FRAGMENT_FRACHT_BRANCH_TYPE}
  mutation editFrachtBranch($frachtBranchData: FrachtBranchInput!) {
    editFrachtBranch(frachtBranchData: $frachtBranchData) {
      ...FragmentFrachtBranchType
    }
  }
`;

export const MUTATION_DELETE_FRACHT_BRANCH_BY_ID = gql`
  mutation deleteFrachtBranchById($id: ID!) {
    deleteFrachtBranchById(id: $id)
  }
`;

export const MUTATION_DELETE_FRACHT_BRANCH_MEMBER_BY_ID = gql`
  mutation deleteFrachtBranchMemberById($id: ID!) {
    deleteFrachtBranchMemberById(id: $id)
  }
`;

export const MUTATION_ADD_AGENT_OFFICE = gql`
  ${FRAGMENT_FRACHT_AGENT_OFFICE_TYPE}
  mutation addAgentOffice($inputData: AgentOfficeInputType) {
    addAgentOffice(inputData: $inputData) {
      ...FragmentFrachtAgentOfficeType
    }
  }
`;

export const MUTATION_EDIT_AGENT_OFFICE_BY_ID = gql`
  ${FRAGMENT_FRACHT_AGENT_OFFICE_TYPE}
  mutation editAgentOfficeById($inputData: AgentOfficeEditType) {
    editAgentOfficeById(inputData: $inputData) {
      ...FragmentFrachtAgentOfficeType
    }
  }
`;

export const MUTATION_DELETE_AGENT_OFFICE_BY_ID = gql`
  mutation deleteAgentOfficeById($id: ID!) {
    deleteAgentOfficeById(id: $id)
  }
`;

export const MUTATION_ADD_AGENT_OFFICE_CONTACT = gql`
  ${FRAGMENT_FRACHT_OFFICE_AGENT_CONTACT_TYPE}
  mutation addAgentOfficeContact($inputData: AgentOfficeContactInputType) {
    addAgentOfficeContact(inputData: $inputData) {
      ...FragmentFrachtOfficeAgentContactType
    }
  }
`;

export const MUTATION_EDIT_AGENT_OFFICE_CONTACT_BY_ID = gql`
  ${FRAGMENT_FRACHT_OFFICE_AGENT_CONTACT_TYPE}
  mutation editAgentOfficeContactById($inputData: AgentOfficeContactEditType) {
    editAgentOfficeContactById(inputData: $inputData) {
      ...FragmentFrachtOfficeAgentContactType
    }
  }
`;

export const MUTATION_DELETE_AGENT_OFFICE_CONTACT_BY_ID = gql`
  mutation deleteAgentOfficeContactById($id: ID!) {
    deleteAgentOfficeContactById(id: $id)
  }
`;

export const MUTATION_CREATE_ORDER = gql`
  ${FRAGMENT_ORDER_DATA_OUTPUT}
  mutation createOrder(
    $transporterOrder: OrderDataInput
    $senderOrder: OrderDataInput
  ) {
    createOrder(
      transporterOrder: $transporterOrder
      senderOrder: $senderOrder
    ) {
      ...OrderDataOutputFragment
    }
  }
`;

export const MUTATION_EDIT_ORDER = gql`
  ${FRAGMENT_ORDER_DATA_OUTPUT}
  mutation editOrder($order: OrderDataEditInput) {
    editOrder(order: $order) {
      ...OrderDataOutputFragment
    }
  }
`;

export const MUTATION_EDIT_LOAD = gql`
  ${FRAGMENT_MY_LOADS_DATA_OUTPUT}
  mutation editLoad($loadId: ID!, $loadDetails: LoadInput) {
    editLoad(loadId: $loadId, loadDetails: $loadDetails) {
      ...LoadDataFragment
    }
  }
`;

export const MUTATION_SET_LOAD_STATUS = gql`
  mutation setLoadStatus($loadId: ID!, $status: loadStatusEnum) {
    setLoadStatus(loadId: $loadId, status: $status)
  }
`;

export const MUTATION_CREATE_LOAD = gql`
  mutation createLoad($loadDetails: LoadInputBiddingRaw, $docs: [Upload]) {
    createLoad(loadDetails: $loadDetails, docs: $docs) {
      shortId
    }
  }
`;

export const MUTATION_CREATE_LOAD_BIDDING = gql`
  mutation createLoadBidding(
    $loadDetails: LoadInputBiddingRaw
    $docs: [Upload]
  ) {
    createLoadBidding(loadDetails: $loadDetails, docs: $docs) {
      shortId
    }
  }
`;

export const MUTATION_SET_BID_STATUS = gql`
  mutation setBidStatus(
    $loadId: ID
    $bidId: ID
    $status: bidStatusEnum
    $reason: String
  ) {
    setBidStatus(
      loadId: $loadId
      bidId: $bidId
      status: $status
      reason: $reason
    )
  }
`;

export const MUTATION_SEND_INVITE_MAIL_REFERRAL = gql`
  mutation sendInviteMailReferral($emails: String!, $type: String!) {
    sendInviteMailReferral(emails: $emails, type: $type)
  }
`;

export const MUTATION_ADD_REPLY_TO_COMPLAINT = gql`
  mutation addReplyToComplaint(
    $complaintId: ID!
    $name: String
    $text: String!
  ) {
    addReplyToComplaint(complaintId: $complaintId, name: $name, text: $text)
  }
`;

export const MUTATION_GENERATE_REFERRAL = gql`
  mutation generateReferral {
    generateReferral
  }
`;

export const MUTATION_REGISTER = gql`
  ${FRAGMENT_AUTH}
  mutation register(
    $email: String!
    $pass: String!
    $name: String!
    $company: String!
    $phone: String!
    $cui: String!
    $address: String!
    $city: String!
    $county: String!
    $country: String!
    $role: companyRolesEnum!
    $meta: [MetaTagsInput]
    $network: Boolean
    $referral: ID
  ) {
    register(
      email: $email
      pass: $pass
      name: $name
      company: $company
      phone: $phone
      cui: $cui
      address: $address
      city: $city
      county: $county
      country: $country
      role: $role
      meta: $meta
      network: $network
      referral: $referral
    ) {
      ...FragmentAuth
    }
  }
`;

export const ADMIN_EDIT_USER = gql`
  ${FRAGMENT_CLIENT_ADMIN}
  mutation adminEditUser(
    $userId: String!
    $description: String
    $name: String!
    $company: String!
    $email: String!
    $emailStatus: Int!
    $phone: String!
    $cui: String!
    $country: String!
    $county: String!
    $town: String!
    $address: String!
    $type: userRolesEnum!
    $active: Int!
    $reason: String!
  ) {
    adminEditUser(
      description: $description
      name: $name
      company: $company
      email: $email
      emailStatus: $emailStatus
      phone: $phone
      cui: $cui
      country: $country
      county: $county
      town: $town
      address: $address
      type: $type
      active: $active
      reason: $reason
    ) {
      ...FragmentClientAdmin
    }
  }
`;

export const SEND_CONFIRMATION_MAIL = gql`
  mutation sendConfirmationMail($email: String!) {
    sendConfirmationMail(email: $email) {
      sent
    }
  }
`;

export const VALIDATE_EMAIL = gql`
  mutation validateEmail($emailCode: ID!) {
    validateEmail(emailCode: $emailCode) {
      emailStatus
      error
    }
  }
`;

export const ADD_USER_TO_COMPANY = gql`
  ${FRAGMENT_CLIENT}
  mutation addUserToCompany(
    $email: String!
    $pass: String!
    $name: String!
    $phone: String!
    $role: userRolesEnum!
    $meta: [MetaTagsInput]
    $network: Boolean
  ) {
    addUserToCompany(
      email: $email
      pass: $pass
      name: $name
      phone: $phone
      role: $role
      meta: $meta
      network: $network
    ) {
      ...FragmentClient
    }
  }
`;

export const ACCEPT_FRIENDSHIP = gql`
  mutation acceptFriendship($referralId: ID!) {
    acceptFriendship(referralId: $referralId)
  }
`;

export const DELETE_USER_FROM_COMPANY = gql`
  mutation deleteUserFromCompany($userId: ID!) {
    deleteUserFromCompany(userId: $userId)
  }
`;

export const FORGOT_PASS = gql`
  mutation forgotPass($email: String, $network: Boolean) {
    forgotPass(email: $email, network: $network) {
      sent
    }
  }
`;

export const CHANGE_PASS_WITH_CODE = gql`
  ${FRAGMENT_AUTH}
  mutation changePassWithCode(
    $emailCode: String
    $pass: String
    $network: Boolean
  ) {
    changePassWithCode(emailCode: $emailCode, pass: $pass, network: $network) {
      ...FragmentAuth
    }
  }
`;

export const MUTATION_CHANGE_PASS = gql`
  ${FRAGMENT_AUTH}
  mutation changePass($oldPass: String, $pass: String, $network: Boolean) {
    changePass(oldPass: $oldPass, pass: $pass, network: $network) {
      ...FragmentAuth
    }
  }
`;

export const MUTATION_EDIT_COMPANY = gql`
  ${FRAGMENT_COMPANY}
  mutation editCompany(
    $name: String!
    $cui: String!
    $country: String!
    $county: String!
    $city: String!
    $address: String!
    $phone: String!
    $website: String
  ) {
    editCompany(
      name: $name
      cui: $cui
      country: $country
      county: $county
      city: $city
      address: $address
      phone: $phone
      website: $website
    ) {
      ...FragmentCompany
    }
  }
`;

export const MUTATION_UPLOAD_COMPANY_DOCUMENTS = gql`
  ${FRAGMENT_COMPANY}
  mutation uploadCompanyDocuments(
    $doc: Upload
    $type: String!
    $info: String
    $replaceFile: String
    $expiresAt: String
  ) {
    uploadCompanyDocuments(
      doc: $doc
      type: $type
      info: $info
      replaceFile: $replaceFile
      expiresAt: $expiresAt
    ) {
      ...FragmentCompany
    }
  }
`;

export const MUTATION_CREATE_INVOICE = gql`
  ${FRAGMENT_INVOICE_DATA_OUTPUT}
  mutation createInvoice($orderId: String) {
    createInvoice(orderId: $orderId) {
      ...FragmentInvoiceDataOutput
    }
  }
`;

export const MUTATION_REMOVE_FRIENDSHIP = gql`
  mutation removeFriendship($relationId: ID!) {
    removeFriendship(relationId: $relationId)
  }
`;

export const MUTATION_REMOVE_ORDER = gql`
  mutation removeOrder($orderId: ID) {
    removeOrder(orderId: $orderId)
  }
`;

export const MUTATION_ADD_LOCATION_PLANNING_ON_TRUCK = gql`
  mutation addLocationPlanningOnTruck($planning: TruckPlanningInput) {
    addLocationPlanningOnTruck(planning: $planning)
  }
`;

export const MUTATION_EDIT_LOCATION_PLANNING_ON_TRUCK = gql`
  mutation editLocationPlanningOnTruck(
    $planningId: ID!
    $planning: TruckPlanningInput
  ) {
    editLocationPlanningOnTruck(planningId: $planningId, planning: $planning)
  }
`;

export const MUTATION_DELETE_LOCATION_PLANNING_ON_TRUCK = gql`
  mutation deleteLocationPlanningOnTruck($planningId: ID!) {
    deleteLocationPlanningOnTruck(planningId: $planningId)
  }
`;

export const MUTATION_SET_TARGET_REVENUE = gql`
  mutation setTargetRevenue($userId: ID!, $targetRevenue: Int!) {
    setTargetRevenue(userId: $userId, targetRevenue: $targetRevenue)
  }
`;

export const MUTATION_SET_TARGET_PROFIT = gql`
  mutation setTargetProfit($userId: ID!, $targetProfit: Int!) {
    setTargetProfit(userId: $userId, targetProfit: $targetProfit)
  }
`;

export const MUTATION_SET_TARGET_CURRENCY = gql`
  mutation setTargetCurrency($userId: ID!, $targetCurrency: String!) {
    setTargetCurrency(userId: $userId, targetCurrency: $targetCurrency)
  }
`;

export const MUTATION_MODIFY_ORDER_DOCUMENT = gql`
  ${FRAGMENT_CLIENT}
  mutation modifyOrderDocument(
    $orderId: ID!
    $fileName: String!
    $status: String
    $type: String
    $deleteFile: Boolean
  ) {
    modifyOrderDocument(
      orderId: $orderId
      fileName: $fileName
      status: $status
      type: $type
      deleteFile: $deleteFile
    ) {
      ...FragmentClient
    }
  }
`;

export const MUTATION_SET_ORDER_STATUS = gql`
  mutation setOrderStatus($orderId: ID!, $status: orderStatusEnum!) {
    setOrderStatus(orderId: $orderId, status: $status)
  }
`;

export const MUTATION_CREATE_COMPLAINT = gql`
  ${FRAGMENT_COMPLAINT}
  mutation createComplaint($complaintData: ComplaintInput!) {
    createComplaint(complaintData: $complaintData) {
      ...FragmentComplaint
    }
  }
`;

export const MUTATION_ACCEPT_LOAD_REQUEST = gql`
  mutation acceptLoadRequest($loadId: ID) {
    acceptLoadRequest(loadId: $loadId)
  }
`;

export const MUTATION_REJECT_LOAD_REQUEST = gql`
  mutation rejectLoadRequest($loadId: ID, $rejectReason: String) {
    rejectLoadRequest(loadId: $loadId, rejectReason: $rejectReason)
  }
`;

export const MUTATION_UPLOAD_COMPLAINT_FILE = `
  mutation($file: Upload!, $complaintId: ID!) {
    uploadComplaintFile(file: $file, complaintId: $complaintId) {
      url
      name
    }
  }
`;

export const MUTATION_DOWNLOAD_COMPLAINT_FILE = gql`
  mutation downloadComplaintFile($complaintId: ID!, $fileName: String!) {
    downloadComplaintFile(complaintId: $complaintId, fileName: $fileName)
  }
`;

export const MUTATION_CREATE_REACTION_FOR_NEWSLETTER_ID = gql`
  ${FRAGMENT_NEWSLETTER_TYPE}
  mutation createReactionForNewsletterId(
    $newsletterId: ID!
    $reaction: String!
  ) {
    createReactionForNewsletterId(
      newsletterId: $newsletterId
      reaction: $reaction
    ) {
      ...FragmentNewsletterType
    }
  }
`;

export const MUTATION_DELETE_REACTION_FOR_NEWSLETTER_ID = gql`
  ${FRAGMENT_NEWSLETTER_TYPE}
  mutation deleteReactionForNewsletterId($newsletterId: ID!) {
    deleteReactionForNewsletterId(newsletterId: $newsletterId) {
      ...FragmentNewsletterType
    }
  }
`;

export const MUTATION_CREATE_COMMENT_FOR_NEWSLETTER_ID = gql`
  ${FRAGMENT_NEWSLETTER_TYPE}
  mutation createCommentForNewsletterId(
    $newsletterId: ID!
    $comment: String!
    $file: Upload
  ) {
    createCommentForNewsletterId(
      newsletterId: $newsletterId
      comment: $comment
      file: $file
    ) {
      ...FragmentNewsletterType
    }
  }
`;

export const MUTATION_DELETE_COMMENT_FOR_NEWSLETTER_ID = gql`
  ${FRAGMENT_NEWSLETTER_TYPE}
  mutation deleteCommentForNewsletterId($newsletterId: ID!, $commentId: ID!) {
    deleteCommentForNewsletterId(
      newsletterId: $newsletterId
      commentId: $commentId
    ) {
      ...FragmentNewsletterType
    }
  }
`;

export const MUTATION_DELETE_NEWSLETTER_BY_ID = gql`
  mutation deleteNewsletterById($id: ID!) {
    deleteNewsletterById(id: $id)
  }
`;

export const MUTATION_ALLOW_COMMENTS_TO_NEWSLETTER = gql`
  mutation allowCommentsToNewsletterId($newsletterId: ID!) {
    allowCommentsToNewsletterId(newsletterId: $newsletterId)
  }
`;

export const MUTATION_BLOCK_COMMENTS_TO_NEWSLETTER = gql`
  mutation blockCommentsToNewsletterId($newsletterId: ID!) {
    blockCommentsToNewsletterId(newsletterId: $newsletterId)
  }
`;

export const MUTATION_CREATE_AIR_TRANSPORT_ORDER = gql`
  ${FRAGMENT_AIR_TRANSPORT_ORDER}
  mutation createAirTransportOrder(
    $airTransportOrderDetails: AirTransportOrderInput!
  ) {
    createAirTransportOrder(
      airTransportOrderDetails: $airTransportOrderDetails
    ) {
      ...FragmentAirTransportOrder
    }
  }
`;

export const MUTATION_EDIT_AIR_TRANSPORT_ORDER = gql`
  ${FRAGMENT_AIR_TRANSPORT_ORDER}
  mutation editAirTransportOrder(
    $airTransportOrderId: ID!
    $airTransportOrderDetails: AirTransportOrderInput!
  ) {
    editAirTransportOrder(
      airTransportOrderId: $airTransportOrderId
      airTransportOrderDetails: $airTransportOrderDetails
    ) {
      ...FragmentAirTransportOrder
    }
  }
`;

export const MUTATION_CREATE_SHIPMENT = gql`
  mutation ($type: AirSeaTypeEnum!) {
    createShipment(type: $type) {
      id
      shortId
      type
    }
  }
`;

export const MUTATION_CREATE_SHIPMENT_FROM_BOL = gql`
  mutation ($type: AirSeaTypeEnum!, $file: Upload!) {
    createShipmentFromBol(type: $type, file: $file) {
      id
      shortId
      type
    }
  }
`;

export const MUTATION_EDIT_SHIPMENT = gql`
  ${FRAGMENT_SHIPMENT}
  mutation ($shipmentData: ShipmentDataInput!, $saveMode: String) {
    editShipmentById(shipmentData: $shipmentData, saveMode: $saveMode) {
      ...FragmentShipment
    }
  }
`;

export const MUTATION_CREATE_SHIPMENT_EVENT = gql`
  mutation createShipmentEvent($shipmentId: ID!, $text: String!, $timestamp: String) {
    createShipmentEvent(shipmentId: $shipmentId, text: $text, timestamp: $timestamp)
  }
`;

export const MUTATION_DELETE_SHIPMENT_EVENT_BY_ID = gql`
  mutation deleteShipmentEventById($id: ID!) {
    deleteShipmentEventById(id: $id)
  }
`;

export const MUTATION_ADD_SUBSCRIBERS_TO_SHIPMENT = gql `
  mutation addSubscribersToShipment($shipmentId: ID!, $subscribers: String!) {
    addSubscribersToShipment(shipmentId: $shipmentId, subscribers: $subscribers)
  }
`

export const MUTATION_REMOVE_SUBSCRIBER_FROM_SHIPMENT = gql `
  mutation removeSubscriberFromShipment($shipmentId: ID!, $subscriber: String!) {
    removeSubscriberFromShipment(shipmentId: $shipmentId, subscriber: $subscriber)
  }
`

export const MUTATION_CREATE_CONTAINER = gql`
  ${FRAGMENT_CONTAINER}
  mutation ($containerData: ContainerCreateDataInput) {
    createContainer(containerData: $containerData) {
      ...FragmentContainer
    }
  }
`;

export const MUTATION_DELETE_CONTAINER_BY_ID = gql`
  mutation deleteContainerById($shipmentId: String!, $id: String!) {
    deleteContainerById(shipmentId: $shipmentId, id: $id)
  }
`;

export const MUTATION_DELETE_BILLING_BY_ID = gql`
  mutation deleteBillingById($shipmentId: String!, $id: String!) {
    deleteBillingById(shipmentId: $shipmentId, id: $id)
  }
`;

export const MUTATION_DELETE_DOCUMENT_BY_ID = gql`
  mutation deleteDocumentById($shipmentId: String!, $id: String!) {
    deleteDocumentById(shipmentId: $shipmentId, id: $id)
  }
`;

export const MUTATION_UPLOAD_DOCUMENT = gql`
  mutation uploadDocument($documentData: DocumentCreateDataInput) {
    uploadDocument(documentData: $documentData) {
      id
      shipmentId
      type
      description
      fileName
      fileType
      bucketS3
      deleted
    }
  }
`;

export const MUTATION_CREATE_BILLING = gql`
  ${FRAGMENT_AIR_SEA_TRANSPORTER}
  mutation createBilling($billingData: BillingCreateData) {
    createBilling(billingData: $billingData) {
      id
      shipmentId
      userId
      companyId
      shortId
      creditorTransporterId
      creditorTransporter {
        ...FragmentAirSeaTransporter
      }
      debtorTransporterId
      debtorTransporter {
        ...FragmentAirSeaTransporter
      }
      charge
      dep
      description
      costCurrency
      osCostAmt
      estimatedCost
      localCost
      costTax
      posted
      sellCurrency
      localSellAmt
      osSellAmt
      sellTax
      invoiceType
      postedRevenue
      paid
      daysAfterEta
      dueDate
      deleted
      billingDate
    }
  }
`;

export const MUTATION_EDIT_BILLING_BY_ID = gql`
  ${FRAGMENT_BILLING}
  mutation editBillingById($billingData: BillingEditData) {
    editBillingById(billingData: $billingData) {
      ...FragmentBilling
    }
  }
`;

export const MUTATION_CREATE_AIR_SEA_TRANSPORTER = gql`
  ${FRAGMENT_AIR_SEA_TRANSPORTER}
  mutation createAirSeaTransporter(
    $transporterData: AirSeaTransporterCreateInput
  ) {
    createAirSeaTransporter(transporterData: $transporterData) {
      ...FragmentAirSeaTransporter
    }
  }
`;

export const MUTATION_EDIT_AIR_SEA_TRANSPORTER_BY_ID = gql`
  ${FRAGMENT_AIR_SEA_TRANSPORTER}
  mutation editAirSeaTransporterById(
    $transporterData: AirSeaTransporterEditInput
  ) {
    editAirSeaTransporterById(transporterData: $transporterData) {
      ...FragmentAirSeaTransporter
    }
  }
`;

export const MUTATION_CREATE_BULK_AIR_TRANSPORT_ORDER_RATE = gql`
  mutation createBulkAirTransportOrderRate(
    $airTransportOrderRateDetails: [AirTransportOrderRateInput]!
    $airTransportOrderId: ID!
  ) {
    createBulkAirTransportOrderRate(
      airTransportOrderRateDetails: $airTransportOrderRateDetails
      airTransportOrderId: $airTransportOrderId
    )
  }
`;

export const MUTATION_CREATE_BULK_AIR_TRANSPORT_ORDER_CHARGE = gql`
  mutation createBulkAirTransportOrderCharge(
    $airTransportOrderChargeDetails: [AirTransportOrderChargeInput]!
    $airTransportOrderId: ID!
  ) {
    createBulkAirTransportOrderCharge(
      airTransportOrderChargeDetails: $airTransportOrderChargeDetails
      airTransportOrderId: $airTransportOrderId
    )
  }
`;

export const MUTATION_CREATE_BANKING_DETAIL = gql`
  ${FRAGMENT_BANKING_DETAIL}
  mutation createBankingDetail($bankingData: BankingDetailCreateInput) {
    createBankingDetail(bankingData: $bankingData) {
      ...FragmentBankingDetail
    }
  }
`;

export const MUTATION_EDIT_BANKING_DETAIL_BY_ID = gql`
  ${FRAGMENT_BANKING_DETAIL}
  mutation editBankingDetailById($bankingData: BankingDetailDataInput) {
    editBankingDetailById(bankingData: $bankingData) {
      ...FragmentBankingDetail
    }
  }
`;

export const MUTATION_CREATE_AIR_SEA_INVOICE = gql`
  ${FRAGMENT_INVOICE}
  mutation createAirSeaInvoice($invoiceData: AirSeaInvoiceCreateData) {
    createAirSeaInvoice(invoiceData: $invoiceData) {
      ...FragmentInvoice
    }
  }
`;

export const MUTATION_EDIT_AIR_SEA_INVOICE_BY_ID = gql`
  ${FRAGMENT_INVOICE}
  mutation editAirSeaInvoiceById($invoiceData: InvoiceEditData) {
    editAirSeaInvoiceById(invoiceData: $invoiceData) {
      ...FragmentInvoice
    }
  }
`;

export const MUTATION_DELETE_AIR_SEA_INVOICE_BY_ID = gql`
  mutation deleteAirSeaInvoiceById($shipmentId: String!, $id: String!) {
    deleteAirSeaInvoiceById(shipmentId: $shipmentId, id: $id)
  }
`;

export const MUTATION_DELETE_SHIPMENT_BY_ID = gql`
  mutation deleteShipmentById($id: String!) {
    deleteShipmentById(id: $id)
  }
`;

export const MUTATION_EDIT_CONTAINER_BY_ID = gql`
  ${FRAGMENT_CONTAINER}
  mutation editContainerById($containerData: ContainerEditDataInput) {
    editContainerById(containerData: $containerData) {
      ...FragmentContainer
    }
  }
`;

export const MUTATION_SET_DOCUMENT_PAID_STATUS = gql`
  ${FRAGMENT_SHIPMENT_DOCUMENT}
  mutation setDocumentPaidStatus($id: String!, $paidStatus: paidStatusEnum) {
    setDocumentPaidStatus(id: $id, paidStatus: $paidStatus) {
      ...FragmentShipmentDocument
    }
  }
`;

export const MUTATION_SET_BILL_PAID_STATUS = gql`
  ${FRAGMENT_BILLING}
  mutation setBillPaidStatus($id: String!, $paid: Boolean) {
    setBillPaidStatus(id: $id, paid: $paid) {
      ...FragmentBilling
    }
  }
`;

export const MUTATION_CHANGE_SHIPMENT_STATUS = gql`
    mutation changeShipmentStatus($shipmentId: ID!, $status: ShipmentStatusEnum!) {
        changeShipmentStatus(shipmentId: $shipmentId, status: $status)
    }
`;

export const MUTATION_SET_INVOICE_PAID_STATUS = gql`
  ${FRAGMENT_INVOICE}
  mutation setInvoicePaidStatus($id: String!, $paidStatus: paidStatusEnum) {
    setInvoicePaidStatus(id: $id, paidStatus: $paidStatus) {
      ...FragmentInvoice
    }
  }
`;

export const MUTATION_SET_BIRTHDAY = gql`
  mutation setBirthday($frachtBranchMemberId: ID!, $birthday: String!) {
    setBirthday(
      frachtBranchMemberId: $frachtBranchMemberId
      birthday: $birthday
    )
  }
`;

export const MUTATION_SET_LAST_VISIT_CONTACTS = gql`
  mutation setLastVisitContacts {
    setLastVisitContacts
  }
`;

export const MUTATION_UPLOAD_DOCUMENTS = `
  mutation uploadDocuments($files:[Upload]) {
    uploadDocuments(files: $files) {
      id
      url
      fileType
      fileName
      thumbUrl
      thumbName
    }
  }
`;

export const MUTATION_DELETE_DOCUMENTS = gql`
  mutation deleteDocuments($documents: [NewsletterDocumentDataInput]) {
    deleteDocuments(documents: $documents)
  }
`;

export const MUTATION_DOWNLOAD_DOCUMENT = gql`
  mutation downloadDocument($fileName: String!) {
    downloadDocument(fileName: $fileName)
  }
`;

export const MUTATION_ACCEPT_SOCIAL_TERMS = gql`
  mutation acceptSocialTerms {
    acceptSocialTerms
  }
`;

export const MUTATION_SUBSCRIBE_EXTERNAL_NEWSLETTER = gql`
  mutation subscribeToExternalNewsletter($subscriptionObj: SubscribeExternalNewsletterDataInput) {
    subscribeToExternalNewsletter(subscriptionObj: $subscriptionObj)
  }
`;

export const MUTATION_CREATE_DEAL = gql`
  ${FRAGMENT_DEAL}
  mutation createDeal($dealInput: DealInput!) {
    createDeal(dealInput: $dealInput) {
      ...FragmentDeal
    }
  }
`;

export const MUTATION_EDIT_DEAL = gql`
  ${FRAGMENT_DEAL}
  mutation editDeal($id: ID!, $dealInput: DealInput!) {
    editDeal(id: $id, dealInput: $dealInput) {
      ...FragmentDeal
    }
  }
`;

export const MUTATION_CHANGE_DEAL_STATUS = gql`
  ${FRAGMENT_DEAL}
  mutation changeDealStatus($id: ID!, $status: dealStatusEnum!) {
    changeDealStatus(id: $id, status: $status) {
      ...FragmentDeal
    }
  }
`;

export const MUTATION_CREATE_FINANCIAL_COST = gql`
  mutation createFinancialCost($financialCostData: [FinancialCostInput]) {
      createFinancialCost(financialCostData: $financialCostData) {
         id
      }
  }
`;

export const MUTATION_CREATE_FINANCIAL_PRESET_ACCOUNT = gql`
  mutation createFinancialPresetAccount($name: String!) {
      createFinancialPresetAccount(name: $name) {
              id
              name
      }
  }
`;

export const MUTATION_CREATE_FINANCIAL_RECURRING_COST = gql`
  mutation createFinancialRecurringCost($financialRecurringCostData: [FinancialRecurringCostInput]) {
      createFinancialRecurringCost(financialRecurringCostData: $financialRecurringCostData) {
         id
      }
  }
`;

export const MUTATION_DELETE_FINANCIAL_COST = gql`
  mutation deleteFinancialCost($id: ID!, $deleteFutureOccurances: Boolean) {
    deleteFinancialCost(id: $id, deleteFutureOccurances: $deleteFutureOccurances)
  }
`;

export const MUTATION_DELETE_FINANCIAL_RECURRING_COST = gql`
  mutation deleteFinancialRecurringCost($id: ID!, $deleteFutureOccurances: Boolean) {
    deleteFinancialRecurringCost(id: $id, deleteFutureOccurances: $deleteFutureOccurances)
  }
`;

export const MUTATION_CREATE_OR_UPDATE_BULK_FINANCIAL_BANK_ACCOUNT = gql`
  mutation createOrUpdateBulkFinancialBankAccount($financialBankAccountData: [FinancialBankAccountInput]) {
      createOrUpdateBulkFinancialBankAccount(financialBankAccountData: $financialBankAccountData) {
         id
      }
  }
`;
