import { gql } from '@apollo/client/core';

export const FRAGMENT_DOCUMENT = gql`
  fragment FragmentDocument on Doc {
    url
    type
    info
    status
    updatedAt
    expiresAt
    fileName
  }
`;

export const SUBFIELDS_FRAGMENT_COMPANY = gql`
  ${FRAGMENT_DOCUMENT}
  fragment SubfieldsFragmentCompany on Company {
    id
    parentCompanyId
    shortId
    name
    cui
    country
    county
    city
    address
    phone
    email
    website
    role
    photo
    active
    totalLoads
    description
    createdAt
    updatedAt
    premium
    documents {
      ...FragmentDocument
    }
    loadFee
  }
`;

export const FRAGMENT_COMPANY = gql`
  ${SUBFIELDS_FRAGMENT_COMPANY}
  fragment FragmentCompany on Company {
    ...SubfieldsFragmentCompany
    parentCompany {
      ...SubfieldsFragmentCompany
    }
  }
`;

export const FRAGMENT_CLIENT_ADMIN = gql`
  ${FRAGMENT_COMPANY}
  fragment FragmentClientAdmin on ClientAdmin {
    userId
    name
    company {
      ...FragmentCompany
    }
    cui
    email
    emailStatus
    emailSent
    phone
    country
    county
    town
    address
    role
    photo
    active
    description
    createdAt
    updatedAt
    transportLicence
    documents {
      url
      type
      info
      status
      fileName
      updatedAt
      expiresAt
    }
    oneSignalIdHash
    oneSignalActive
    oneSignalTag {
      key
      value
    }
    lastLogin
    lastLoginApp
    appId
    appPlatform
    appVersion
    targetRevenue
    targetProfit
    targetCurrency
  }
`;

export const FRAGMENT_CLIENTS_ADMIN = gql`
  ${FRAGMENT_CLIENT_ADMIN}
  fragment FragmentClientsAdmin on AdminClients {
    count
    clients {
      ...FragmentClientAdmin
    }
  }
`;

export const FRAGMENT_AUTH = gql`
  fragment FragmentAuth on Auth {
    token
    exp
    userId
    refreshToken
    expRefresh
  }
`;

export const FRAGMENT_CHAT = gql`
  fragment FragmentChat on Chat {
    id
    clientId
    shipperId
    carrierId
    ticketId
    status
    createdAt
    shipperCompany
    carrierCompany
  }
`;

export const FRAGMENT_CHATS = gql`
  ${FRAGMENT_CHAT}
  fragment FragmentChats on Chats {
    count
    chats {
      ...FragmentChat
    }
  }
`;

export const FRAGMENT_CURRENCY = gql`
  fragment FragmentCurrency on Currency {
    id
    code
    symbol
  }
`;

export const FRAGMENT_CONTACT = gql`
  fragment FragmentContact on Contact {
    id
    name
    phone
    email
  }
`;

export const FRAGMENT_CONTACT_FOR_ADDRESS = gql`
  fragment FragmentContactForAddress on ContactForAddress {
    name
    phone
    email
  }
`;

export const FRAGMENT_ADDRESS = gql`
  ${FRAGMENT_CONTACT_FOR_ADDRESS}
  fragment FragmentAddress on Address {
    id
    alias
    name
    address
    town
    county
    country
    code
    latitude
    longitude
    contact {
      ...FragmentContactForAddress
    }
  }
`;

export const FRAGMENT_LOCATION_DETAILS = gql`
  ${FRAGMENT_ADDRESS}
  fragment FragmentLoadLocationDetails on LoadLocationDetails {
    address {
      ...FragmentAddress
    }
    addressId
    from
    to
  }
`;

export const FRAGMENT_DIMENSIONS_FIELDS = gql`
  fragment FragmentDimensionsFields on Dimensions {
    length
    width
    height
  }
`;

export const FRAGMENT_LOAD_FIELDS = gql`
  ${FRAGMENT_DIMENSIONS_FIELDS}
  fragment FragmentLoadFields on LoadFields {
    truckTypes
    truckFeatures
    loadModel
    dimensions {
      ...FragmentDimensionsFields
    }
    weight
    numberOfEuroPallets
    content
    packagingType
    stackedCargo
    coldStorage
  }
`;

export const FRAGMENT_ORDER_COMPANY_OUTPUT = gql`
  fragment FragmentOrderCompanyOutput on OrderCompanyOutput {
    companyId
    companyName
    cui
    email
    phone
    address
    mailingAddress
    city
    county
    country
  }
`;

export const FRAGMENT_COMPANY_DATA_OUTPUT = gql`
  ${FRAGMENT_DOCUMENT}
  ${FRAGMENT_CLIENT_ADMIN}
  ${FRAGMENT_COMPANY}
  fragment CompanyDataFragment on CompanyAdmin {
    shortId
    id
    parentCompanyId
    parentCompany {
      ...FragmentCompany
    }
    name
    cui
    country
    county
    city
    address
    phone
    website
    role
    photo
    active
    totalLoads
    description
    createdAt
    updatedAt
    documents {
      ...FragmentDocument
    }
    users {
      ...FragmentClientAdmin
    }
    loadFee
  }
`;

export const FRAGMENT_CHAT_DATA_OUTPUT = gql`
  fragment ChatDataFragment on Chat {
    id
    companyId
    carrierId
    loadId
    status
    createdAt
    shipper
    carrier
  }
`;

export const FRAGMENT_CONTACT_FOR_ADDRESS_OUTPUT = gql`
  fragment ContactForAddressFragment on ContactForAddress {
    name
    phone
    email
  }
`;

export const FRAGMENT_LOAD_LOCATION_DETAILS_OUTPUT = gql`
  ${FRAGMENT_ADDRESS}
  fragment LoadLocationDetailsFragment on LoadLocationDetails {
    address {
      ...FragmentAddress
    }
    addressId
    from
    to
  }
`;

export const FRAGMENT_LOAD_EVENT = gql`
  fragment LoadEventFragment on LoadEvent {
    id
    driverId
    truckId
    loadId
    createdAt
    status
    info
    documents
  }
`;

export const FRAGMENT_MY_LOADS_DATA_OUTPUT = gql`
  ${FRAGMENT_COMPANY_DATA_OUTPUT}
  ${FRAGMENT_CHAT_DATA_OUTPUT}
  ${FRAGMENT_LOAD_LOCATION_DETAILS_OUTPUT}
  ${FRAGMENT_ADDRESS}
  ${FRAGMENT_CURRENCY}
  ${FRAGMENT_CONTACT}
  ${FRAGMENT_LOAD_EVENT}
  ${FRAGMENT_LOAD_FIELDS}
  fragment LoadDataFragment on Load {
    id
    shortId
    ownerUserId
    ownerCompanyId
    ownerCompany {
      ...CompanyDataFragment
    }
    publisherUserId
    publisherCompanyId
    publisherCompany {
      ...CompanyDataFragment
    }
    carrierId
    carrier {
      ...CompanyDataFragment
    }
    orderId
    status
    createdAt
    attachments
    chatList {
      count
      chats {
        ...ChatDataFragment
      }
    }
    pickUp {
      ...LoadLocationDetailsFragment
    }
    dropOff {
      ...LoadLocationDetailsFragment
    }
    distance
    load {
      ...FragmentLoadFields
    }
    otherDetails
    price
    currency {
      ...FragmentCurrency
    }
    paymentDelay
    unreadMessages
    openChats
    contactId
    contact {
      ...FragmentContact
    }
    events {
      ...LoadEventFragment
    }
    truckId
    driverIds
    type
    bidding
    bidConfirmed
    biddingAveragePrice
    bestBiddingPrice
    bidWinPrice
    biddingStart
    biddingEnd
    biddingBudget
    docs {
      companyId
      name
      date
    }
  }
`;

export const FRAGMENT_ORDER_COSTS_OUTPUT = gql`
  fragment OrderCostsOutputFragment on OrderCostsOutput {
    costWithoutVat
    costVatPercent
    costCurrency
    costWithVat
    costPayableUntil
  }
`;

export const FRAGMENT_SEARCH_COMPANY = gql`
  fragment SearchCompanyFragment on SearchCompany {
    id
    noId
    name
    shortId
    address
    city
    county
    country
    phone
    email
    cui
  }
`;

export const FRAGMENT_ORDER_DOCUMENT = gql`
  fragment OrderDocumentFragment on OrderDocument {
    fileName
    url
    status
    type
    createdAt
  }
`;

export const FRAGMENT_COMPLAINT_DOCUMENT = gql`
  fragment ComplaintDocumentFragment on ComplaintDocument {
    name
    url
  }
`;

export const FRAGMENT_COMPLAINT_REPLY = gql`
  fragment ComplaintReplyFragment on ComplaintReply {
    text
    name
    approved
    date
  }
`;

export const FRAGMENT_COMPANY_RELATION = gql`
  ${FRAGMENT_COMPANY}
  fragment FragmentCompanyRelation on CompanyRelation {
    id
    companyId
    company {
      ...FragmentCompany
    }
    friendCompanyId
    friendCompany {
      ...FragmentCompany
    }
    friendAlias
    friendAccepted
    friendEmail
    active
    code
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_FINANCE = gql`
  fragment FragmentFinance on Finance {
    senderBudget
    transporterBudget
    balance
    currency
    userId
    date
  }
`;

export const FRAGMENT_INVOICE_DATA_OUTPUT = gql`
  fragment FragmentInvoiceDataOutput on InvoiceDataOutput {
    id
    shortId
    loadId
    loadShortId
    companyId
    userId
    orderId
    orderShortId
    totalWithoutVat
    totalWithVat
    vat
    currency
    invoiceData {
      companyName
      companyCui
      companyJ
      companyAddress
      payToCompanyName
      payToCompanyCui
      payToCompanyJ
      payToCompanyAddress
      payToCompanyAccountNumber
      payToCompanyEmail
      loadAddressFrom
      loadAddressTo
      loadWeight
      loadPalletsNumber
      loadLoadingDate
      loadUnloadingDate
      orderCmrNumber
      orderNumber
      numberOfOrders
      orderPrice
      orderCurrency
      vatPercent
      invoiceNumber
      invoiceMadeByName
      invoiceCreatedAt
      invoiceTotalPrice
      invoiceNumberOfDaysToPay
      invoiceToPayAtDate
    }
    dueDate
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_GPS_DEVICE = gql`
  fragment FragmentGpsDevice on GpsDevice {
    id
    gpsId
    gpsImei
    companyId
    truckId
    gpsActive
    deleted
    createdByUserId
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_TRUCK_PLANNING = gql`
  fragment FragmentTruckPlanning on TruckPlanning {
    id
    truckId
    companyId
    startingLocation
    endingLocations
    isFtl
    startingLocationGpsData {
      lat
      lng
    }
    startingLocationDate
    details
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_TRUCK = gql`
  ${FRAGMENT_CURRENCY}
  ${FRAGMENT_TRUCK_PLANNING}
  ${FRAGMENT_GPS_DEVICE}
  fragment FragmentTruck on Truck {
    id
    shortId
    companyId
    name
    vehicleCategory
    brand
    year
    fuelAvg
    volume
    floorLength
    containerType
    euroClass
    traction
    length
    width
    height
    weight
    truckLicensePlate
    trailerLicensePlate
    price
    currency {
      ...FragmentCurrency
    }
    currencyId
    vat
    paymentDelay
    truckDescription
    otherDetails
    loadedVolume
    truckFeatures
    active
    deleted
    loadModel
    createdAt
    updatedAt
    documents {
      url
      type
      info
      status
      updatedAt
      expiresAt
      fileName
    }
    planning {
      ...FragmentTruckPlanning
    }
    gpsDevice {
      ...FragmentGpsDevice
    }
    available
  }
`;

export const FRAGMENT_TARGET = gql`
  fragment FragmentTarget on Target {
    userId
    targetRevenue
    targetProfit
    targetCurrency
  }
`;

export const FRAGMENT_COMPLAINT = gql`
  ${FRAGMENT_COMPANY}
  fragment FragmentComplaint on Complaint {
    id
    complainerEmail
    complainerCompanyName
    complainerCui
    complainerCompanyId
    complainerCompany {
      ...FragmentCompany
    }
    targetEmail
    targetCompanyName
    targetCompanyCui
    targetCompanyId
    targetCompany {
      ...FragmentCompany
    }
    documents {
      name
      url
    }
    replies {
      text
      name
      approved
      date
    }
    status
    type
    resolution
    text
    acceptedTerms
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_FRACHT_BRANCH_MEMBER_TYPE = gql`
  fragment FragmentFrachtBranchMemberType on FrachtBranchMemberType {
    id
    country
    companyName
    addressDetails
    postalCode
    city
    country
    state
    street
    longitude
    latitude
    mrMrs
    firstName
    name
    department
    function
    additionalTitle
    phone
    mobile
    fax
    email
    skype
    motherLanguage
    otherLanguages
    specials
    website
    addInfo
    firstNameListUpdateResponsible
    nameListUpdateResponsible
    isGeneralManager
    isAgentManager
    isBranchManager
    managedBranchId
    frachtBranchId
    frachtBranch {
      id
      companyName
    }
    profileThumbUrl
    profilePhotoUrl
    birthday
    userId
    lastActive
    hiringDate
  }
`;

export const FRAGMENT_FRACHT_BRANCH_TYPE = gql`
  ${FRAGMENT_FRACHT_BRANCH_MEMBER_TYPE}
  fragment FragmentFrachtBranchType on FrachtBranchType {
    id
    country
    companyName
    addressDetails
    postalCode
    city
    country
    state
    street
    region
    longitude
    latitude
    mrMrs
    firstName
    name
    department
    function
    additionalTitle
    phone
    mobile
    fax
    email
    skype
    motherLanguage
    otherLanguages
    specials
    website
    addInfo
    firstNameListUpdateResponsible
    nameListUpdateResponsible
    frachtBranchMember {
      ...FragmentFrachtBranchMemberType
    }
    adminId
    admin {
      id
      companyName
    }
  }
`;

export const FRAGMENT_FRACHT_USER_TYPE = gql`
  ${FRAGMENT_FRACHT_BRANCH_MEMBER_TYPE}
  fragment FragmentFrachtUserType on FrachtUserType {
    id
    country
    companyName
    addressDetails
    postalCode
    city
    country
    state
    street
    longitude
    latitude
    mrMrs
    firstName
    name
    department
    function
    additionalTitle
    phone
    mobile
    fax
    email
    skype
    motherLanguage
    otherLanguages
    specials
    website
    addInfo
    firstNameListUpdateResponsible
    nameListUpdateResponsible
    region
    frachtBranchMember {
      ...FragmentFrachtBranchMemberType
    }
    profileThumbUrl
    profilePhotoUrl
    createdAt
    updatedAt
    frachtBranchId
    userId
  }
`;

export const FRAGMENT_FRACHT_USERS = gql`
  ${FRAGMENT_FRACHT_USER_TYPE}
  fragment FragmentFrachtUsers on FrachtUsers {
    count
    frachtUsers {
      ...FragmentFrachtUserType
    }
    officeManagers {
      ...FragmentFrachtUserType
    }
  }
`;

export const FRAGMENT_FRACHT_MEMBERS = gql`
  ${FRAGMENT_FRACHT_BRANCH_MEMBER_TYPE}
  fragment FragmentFrachtMembers on FrachtBranchMembers {
    count
    frachtUsers {
      ...FragmentFrachtBranchMemberType
    }
  }
`;

export const FRAGMENT_FRACHT_AGENT_OFFICE_TYPE = gql`
  fragment FragmentFrachtAgentOfficeType on FrachtAgentOfficeType {
    id
    region
    agent
    agentName
    country
    city
    street
    addAddressDetails
    latitude
    longitude
    phone
    fax
    email
    website
    createdAt
    updatedAt
    agentServiceAgreementType 
    agentServiceAgreementDate
    agentSince
    businessType
    companyRegistrationNo
    taxNo
    iataNo
    termsOfPayment
    profitShare
    invoicingInstructions
    specialRemarks
  }
`;

export const FRAGMENT_FRACHT_AGENT_OFFICES = gql`
  ${FRAGMENT_FRACHT_AGENT_OFFICE_TYPE}
  fragment FragmentFrachtAgentOffices on FrachAgentOffices {
    count
    frachtAgentOffices {
      ...FragmentFrachtAgentOfficeType
    }
  }
`;

export const FRAGMENT_FRACHT_OFFICE_AGENT_CONTACT_TYPE = gql`
  ${FRAGMENT_FRACHT_AGENT_OFFICE_TYPE}
  fragment FragmentFrachtOfficeAgentContactType on FrachtOfficeAgentContactType {
    id
    agentOfficeId
    agentOffice {
      ...FragmentFrachtAgentOfficeType
    }
    region
    country
    companyName
    street
    addressDetails
    postalCode
    city
    state
    longitude
    latitude
    mrMrs
    firstName
    name
    department
    function
    additionalTitle
    phone
    mobile
    fax
    email
    skype
    motherLanguage
    otherLanguages
    specials
    website
    addInfo
    firstNameListUpdateResponsible
    nameListUpdateResponsible
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_FRACHT_OFFICE_AGENT_CONTACTS_TYPE = gql`
  ${FRAGMENT_FRACHT_OFFICE_AGENT_CONTACT_TYPE}
  fragment FragmentFrachtOfficeAgentContactsType on FrachtOfficeAgentContactsType {
    count
    frachtOfficeAgentContacts {
      ...FragmentFrachtOfficeAgentContactType
    }
  }
`;

export const FRAGMENT_CLIENT = gql`
  ${FRAGMENT_COMPANY}
  ${FRAGMENT_DOCUMENT}
  ${FRAGMENT_FRACHT_BRANCH_MEMBER_TYPE}
  fragment FragmentClient on Client {
    userId
    id
    name
    company {
      ...FragmentCompany
    }
    companyId
    cui
    email
    emailStatus
    emailSent
    phone
    country
    county
    town
    address
    role
    photo
    active
    description
    profileThumbUrl
    profilePhotoUrl
    lastMarketNewsVisit
    lastSocialVisit
    lastCompanyNewsVisit
    createdAt
    updatedAt
    transportLicence
    documents {
      ...FragmentDocument
    }
    oneSignalIdHash
    oneSignalActive
    oneSignalTag {
      key
      value
    }
    targetRevenue
    targetProfit
    targetCurrency
    lastLogin
    frachtBranchMember {
      ...FragmentFrachtBranchMemberType
    }
    lastActive
    acceptedSocialTerms
    acceptedSocialTermsTimestamp
    tenantId
  }
`;

export const FRAGMENT_ORDER_DATA_OUTPUT = gql`
  ${FRAGMENT_CLIENT}
  ${FRAGMENT_ORDER_COMPANY_OUTPUT}
  ${FRAGMENT_MY_LOADS_DATA_OUTPUT}
  ${FRAGMENT_ORDER_COSTS_OUTPUT}
  ${FRAGMENT_ORDER_DOCUMENT}
  fragment OrderDataOutputFragment on OrderDataOutput {
    id
    orderNo
    user {
      ...FragmentClient
    }
    transporter {
      ...FragmentOrderCompanyOutput
    }
    sender {
      ...FragmentOrderCompanyOutput
    }
    load {
      ...LoadDataFragment
    }
    costs {
      ...OrderCostsOutputFragment
    }
    budgetCosts {
      ...OrderCostsOutputFragment
    }
    vehicle {
      vehicleDetails
      vehiclePlate
      trailerPlate
    }
    specialInstructions
    status
    createdAt
    updatedAt
    documents {
      ...OrderDocumentFragment
    }
  }
`;

export const FRAGMENT_NEWSLETTER_COMMENT_TYPE = gql`
  fragment FragmentNewsletterCommentType on NewsletterCommentType {
    id
    userName
    userId
    comment
    image
    thumb
    profileThumbUrl
    taggedUsers {
      id
      name
      firstName
      userId
    }
    createdAt
  }
`;

export const FRAGMENT_LAST_NEWSLETTERS_TIMESTAMP_TYPE = gql`
  fragment FragmentLastNewsletterTimestampType on LastNewsletterTimestampType {
    id
    newsletterType
    timestamp
  }
`;

export const FRAGMENT_CONTENT_TAG_TYPE = gql`
  fragment FragmentContentTagType on ContentTagType {
    id
    text
    type
  }
`;

export const FRAGMENT_NEWSLETTER_REACTION_TYPE = gql`
  fragment FragmentNewsletterReactionType on NewsletterReactionType {
    id
    userName
    userId
    reaction
    profileThumbUrl
    createdAt
  }
`;

export const FRAGMENT_NEWSLETTER_DOCUMENT_TYPE = gql`
  fragment FragmentNewsletterDocumentType on NewsletterDocument {
    id
    url
    fileType
    fileName
    thumbUrl
    thumbName
  }
`;

export const FRAGMENT_NEWSLETTER_TYPE = gql`
  ${FRAGMENT_NEWSLETTER_COMMENT_TYPE}
  ${FRAGMENT_NEWSLETTER_REACTION_TYPE}
  ${FRAGMENT_NEWSLETTER_DOCUMENT_TYPE}
  fragment FragmentNewsletterType on NewsletterType {
    id
    title
    text
    image
    thumb
    profileThumbUrl
    comments {
      ...FragmentNewsletterCommentType
    }
    reactions {
      ...FragmentNewsletterReactionType
    }
    documents {
      ...FragmentNewsletterDocumentType
    }
    reactionCount
    commentCount
    userName
    userId
    type
    myReaction {
      ...FragmentNewsletterReactionType
    }
    allowComments
    taggedUsers {
      id
      name
      firstName
      userId
    }
    external
    pinned
    createdAt
    updatedAt
  }
`;
export const FRAGMENT_AIRLINE = gql`
  fragment FragmentAirline on Airline {
    id
    name
    iata
    awbPrefix
    country
  }
`;

export const FRAGMENT_COUNTRY = gql`
  fragment FragmentCountry on CountryOutput {
    id
    name
  }
`;

export const FRAGMENT_DEPARTMENT = gql`
  fragment FragmentDepartment on DepartmentOutput {
    id
    name
  }
`;

export const FRAGMENT_POST = gql`
  ${FRAGMENT_CLIENT}
  fragment FragmentPost on PostOutput {
    id
    content
    postType
    createdBy {
      ...FragmentClient
    }
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_DEAL = gql`
  ${FRAGMENT_CLIENT}
  ${FRAGMENT_COUNTRY}
  ${FRAGMENT_DEPARTMENT}
  ${FRAGMENT_DEPARTMENT}
  ${FRAGMENT_POST}
  ${FRAGMENT_CLIENT}
  fragment FragmentDeal on DealOutput {
    id
    roadOrders
    seaOrders
    airOrders
    projectOrders
    targetCompany
    period
    dealValue
    currency
    comment
    status
    createdById
    createdBy {
      ...FragmentClient
    }
    subscribedCountries {
      ...FragmentCountry
    }
    subscribedDepartments {
      ...FragmentDepartment
    }
    subscribedUsers {
      ...FragmentClient
    }
    latestPost {
      ...FragmentPost
    }
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_AIR_TRANSPORT_ORDER = gql`
  fragment FragmentAirTransportOrder on AirTransportOrder {
    id
    shipperAccountNumber
    shipperNameAndAddress
    awbName
    consigneeAccountNumber
    consigneeNameAndAddress
    icaNameAndCity
    icaIataCode
    icaAccountNo
    awbNumber
    awbAirportDeparture
    issuedBy
    accountingInformation
    shipmentReferenceNumber
    shipmentInformation
    departure
    routeOneTo
    routeOneBy
    routeTwoTo
    routeTwoBy
    routeThreeTo
    routeThreeBy
    destination
    flightDate
    currency
    chcg
    valueCarriage
    valueCustoms
    wtVal
    amountOfInsurance
    requirements
    sci
    chargesWeightPrepaid
    chargesWeightCollect
    valuationChargePrepaid
    valuationChargeCollect
    chargesTaxPrepaid
    chargesTaxCollect
    chargesOtherDueAgentPrepaid
    chargesOtherDueAgentCollect
    chargesOtherDueCarrierPrepaid
    chargesOtherDueCarrierCollect
    chargesTotalPrepaid
    chargesTotalCollect
    ccChargesCurrencyRates
    ccChargesInDest
    ccChargesAtDest
    ccChargesTotal
    shipperCertification
    shipperSignature
    carrierExecution
    carrierDate
    carrierPlace
    carrierSignature
    other
    type
    linkedAirBill
    rates {
      id
      pieces
      grossWeight
      grossWeightMetric
      rateClass
      itemNumber
      rateCharge
      total
      autoCalculations
      natureAndQuantity
      extraDescription
      airTransportOrderId
      chargeableWeight
      dimensions {
        length
        width
        height
        metricOne
        pieces
        weight
        metricTwo
      }
    }
    charges {
      id
      description
      amount
      entitlement
    }
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_NEWSLETTERS_TYPE = gql`
  ${FRAGMENT_NEWSLETTER_TYPE}
  fragment FragmentNewslettersType on NewslettersType {
    count
    newsletters {
      ...FragmentNewsletterType
    }
  }
`;

export const FRAGMENT_AIR_SEA_TRANSPORTER = gql`
  fragment FragmentAirSeaTransporter on AirSeaTransporter {
    id
    name
    address
    contact
    phone
    cui
    tag
    type
    transporterType
    defaultSubscribers
  }
`;

export const FRAGMENT_AIR_SEA_TRANSPORTERS_DATA_OUTPUT = gql`
  ${FRAGMENT_AIR_SEA_TRANSPORTER}
  fragment FragmentAirSeaTransportersDataOutput on AirSeaTransportersDataOutput {
    count
    transporters {
      ...FragmentAirSeaTransporter
    }
  }
`;

export const FRAGMENT_BANKING_DETAIL = gql`
  fragment FragmentBankingDetail on BankingDetail {
    id
    name
    regNo
    vatCode
    address
    county
    country
    email
    phone
    capital
    active
    ibanEur
    ibanUsd
    ibanRon
    swift
    createdAt
  }
`;

export const FRAGMENT_AIR_SEA_INVOICE_ENTRY = gql`
  fragment FragmentAirSeaInvoiceEntry on AirSeaInvoiceEntry {
    detailsMain
    detailsSecondary
    unit
    qty
    unitPrice
    value
    vatValue
  }
`;

export const FRAGMENT_INVOICE = gql`
  ${FRAGMENT_CLIENT}
  ${FRAGMENT_COMPANY}
  ${FRAGMENT_BANKING_DETAIL}
  ${FRAGMENT_AIR_SEA_INVOICE_ENTRY}
  fragment FragmentInvoice on Invoice {
    id
    shipmentId
    userId
    user {
      ...FragmentClient
    }
    companyId
    company {
      ...FragmentCompany
    }
    shortId
    supBankingDetailsId
    supBankingDetails {
      ...FragmentBankingDetail
    }
    cliBankingDetailsId
    cliBankingDetails {
      ...FragmentBankingDetail
    }
    paymentTerm
    vatRate
    supCurrency
    cliCurrency
    exchangeRate
    extraDetails
    entries {
      ...FragmentAirSeaInvoiceEntry
    }
    issuerName
    issuerId
    issuerDetails
    totalCost
    paidStatus
    dueDate
    profit
    sendToEmail
    createdAt
  }
`;

export const FRAGMENT_AIR_TRANSPORT_ORDER_RATE = gql`
  fragment FragmentAirTransportOrderRate on AirTransportOrderRate {
    id
    pieces
    grossWeight
    grossWeightMetric
    rateClass
    itemNumber
    rateCharge
    total
    autoCalculations
    natureAndQuantity
    chargableWeight
    dimensions
    extraDescription
    airTransportOrderId
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_SHIPMENT_DOCUMENT = gql`
  fragment FragmentShipmentDocument on Document {
    id
    shipmentId
    type
    description
    fileName
    fileType
    bucketS3
    paidStatus
    dueDate
    billingId
    deleted
    createdAt
  }
`;
export const FRAGMENT_EXTERNAL_INVOICE = gql`
  fragment FragmentExternalInvoice on ExternalInvoice {
    id
    seriesName
    number
    link
  }
`
export const FRAGMENT_BILLING = gql`
  ${FRAGMENT_CLIENT}
  ${FRAGMENT_COMPANY}
  ${FRAGMENT_AIR_SEA_TRANSPORTER}
  ${FRAGMENT_SHIPMENT_DOCUMENT}
  ${FRAGMENT_EXTERNAL_INVOICE}
  fragment FragmentBilling on Billing {
    id
    shipmentId
    userId
    user {
      ...FragmentClient
    }
    companyId
    company {
      ...FragmentCompany
    }
    shortId
    creditorTransporterId
    creditorTransporter {
      ...FragmentAirSeaTransporter
    }
    debtorTransporterId
    debtorTransporter {
      ...FragmentAirSeaTransporter
    }
    charge
    dep
    description
    costCurrency
    osCostAmt
    estimatedCost
    localCost
    costTax
    posted
    sellCurrency
    osSellAmt
    localSellAmt
    sellTax
    invoiceType
    postedRevenue
    paid
    daysAfterEta
    dueDate
    billingDate
    fixedDueDate
    document {
      ...FragmentShipmentDocument
    }
    externalInvoiceId
    externalInvoice {
      ...FragmentExternalInvoice
    }
    deleted
  }
`;

export const FRAGMENT_CONTAINER = gql`
  fragment FragmentContainer on Container {
    id
    shipmentId
    sealNo
    sealedBy
    mode
    type
    packs
    pkty
    container
    weight
    volume
    origin
    commodity
    deleted
  }
`;

export const FRAGMENT_CONTAINER_DETAILS_BLOB = gql`
  fragment FragmentContainerDetailsBlob on ContainerDetailsBlob {
    container
    sealNo
    containerMode
    containerType
    containerDetails
  }
`;

export const FRAGMENT_SHIPMENT_FINANCIAL_DATA_OUTPUT = gql`
  fragment FragmentShipmentFinancialDataOutput on ShipmentFinancialDataOutput {
    cost
    revenue
    profit
    profitability
  }
`;

export const FRAGMENT_SHIPMENT_FINANCIAL_CASH_FLOW_OUTPUT = gql`
  fragment FragmentShipmentFinancialCashFlowOutput on ShipmentFinancialCashFlowOutput {
    cost
    revenue
    total
    difference
  }
`;

export const FRAGMENT_SHIPMENT_EVENT = gql`
  fragment FragmentShipmentEvent on ShipmentEvent {
    id
    shipmentId
    timestamp
    text
    status
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_SHIPMENT = gql`
  ${FRAGMENT_AIR_SEA_TRANSPORTER}
  ${FRAGMENT_CONTAINER}
  ${FRAGMENT_SHIPMENT_DOCUMENT}
  ${FRAGMENT_BILLING}
  ${FRAGMENT_CONTAINER_DETAILS_BLOB}
  ${FRAGMENT_SHIPMENT_FINANCIAL_DATA_OUTPUT}
  ${FRAGMENT_SHIPMENT_EVENT}
  fragment FragmentShipment on Shipment {
    id
    shortId
    userId
    user {
      id
      companyId
      name
    }
    companyId
    company {
      id
      name
    }
    containerMode
    voyage
    vessel
    loadingTransporterName
    unloadingTransporterName
    loadingPortId
    loadingPort {
      id
      code
      city
      country
      type
    }
    loadingPortCode
    offloadingPortId
    offloadingPort {
      id
      code
      city
      country
      type
    }
    offloadingPortCode
    etd
    atd
    eta
    ata
    bol
    payment
    loading
    loadingTransporterId
    loadingTransporter {
      ...FragmentAirSeaTransporter
    }
    loadingTransporterName
    unloading
    unloadingTransporterId
    unloadingTransporter {
      ...FragmentAirSeaTransporter
    }
    unloadingTransporterName
    pickupAgentTransporterId
    pickupAgentTransporter {
      ...FragmentAirSeaTransporter
    }
    deliveryAgentTransporterId
    deliveryAgentTransporter {
      ...FragmentAirSeaTransporter
    }
    localCustomerDetails
    localCustomerTransporterId
    localCustomerTransporter {
      ...FragmentAirSeaTransporter
    }
    description
    marksNumbers
    incoterm
    orderReference
    hbl
    state
    status
    type
    deleted
    containers {
      count
      containers {
        ...FragmentContainer
      }
    }
    documents {
      count
      documents {
        ...FragmentShipmentDocument
      }
    }
    billings {
      ...FragmentBilling
    }
    carrierId
    carrier {
      ...FragmentAirSeaTransporter
    }
    issueDate
    releaseType
    createdAt
    notify
    notify1
    notify2
    originalHbl
    originalMbl
    containerDetailsBlob {
      ...FragmentContainerDetailsBlob
    }
    financial {
      ...FragmentShipmentFinancialDataOutput
    }
    hasInvoice
    hasUnpaidIncomingInvoice
    hasUnpaidOutgoingInvoice
    shipmentEvents {
      ...FragmentShipmentEvent
    }
    subscribers
    whatsappGroup
    containerNo
  }
`;

export const FRAGMENT_SHIPMENT_WITH_FINANCIAL_DATA_OUTPUT = gql`
  ${FRAGMENT_SHIPMENT}
  ${FRAGMENT_SHIPMENT_FINANCIAL_DATA_OUTPUT}
  fragment FragmentShipmentWithFinancialDataOutput on ShipmentWithFinancialDataOutput {
    count
    shipments {
      ...FragmentShipment
    }
    financialTotals {
      ...FragmentShipmentFinancialDataOutput
    }
  }
`;

export const FRAGMENT_SHIPMENT_WITH_CASH_FLOW_DATA_OUTPUT = gql`
  ${FRAGMENT_SHIPMENT}
  ${FRAGMENT_SHIPMENT_FINANCIAL_CASH_FLOW_OUTPUT}
  fragment FragmentShipmentWithCashFlowDataOutput on ShipmentWithCashFlowDataOutput {
    count
    shipments {
      ...FragmentShipment
    }
    cashFlowFinancial {
      ...FragmentShipmentFinancialCashFlowOutput
    }
  }
`;

export const FRAGMENT_SHIPMENT_HISTORY = gql`
  fragment FragmentShipmentHistory on ShipmentHistory {
    id
    userId
    userName
    shipmentId
    tableName
    tableId
    msg
    changes {
      col
      old
      new
    }
    createdAt
  }
`;

export const FRAGMENT_FINANCIAL_COST = gql`
  ${FRAGMENT_COMPANY}
  ${FRAGMENT_CLIENT}
  fragment FragmentFinancialCost on FinancialCost {
    id
    userId
    user {
      ...FragmentClient
    }
    companyId
    company {
      ...FragmentCompany
    }
    account
    type
    debit
    credit
    currency
    date
    recurency
    recurringCostId
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_FINANCIAL_PRESET_ACCOUNT = gql`
  fragment FragmentFinancialPresetAccount on FinancialPresetAccount {
    id
    name
    userId
    companyId
    user {
      id
      name
      role
      profileThumbUrl
    }
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_FINANCIAL_RECURRING_COST = gql`
  ${FRAGMENT_COMPANY}
  ${FRAGMENT_CLIENT}
  fragment FragmentFinancialRecurringCost on FinancialRecurringCost {
    id
    userId
    user {
      ...FragmentClient
    }
    companyId
    company {
      ...FragmentCompany
    }
    account
    type
    debit
    credit
    currency
    startDate
    endDate
    recurency
    weekdayOfPayment
    dayOfPayment
    monthOfPayment
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_FINANCIAL_COST_VALUE = gql`
  fragment FragmentFinancialCostValue on FinancialCostValue {
    eur
    ron
    usd
    chf
    czk
    mdl
    pln
    uah
    gbp
    rub
    nok
    huf
    hrk
    egp
    try
  }
`;


export const FRAGMENT_FINANCIAL_BANK_ACCOUNT = gql`
  ${FRAGMENT_COMPANY}
  ${FRAGMENT_CLIENT}
  fragment FragmentFinancialBankAccount on FinancialBankAccount {
    id
    userId
    user {
      ...FragmentClient
    }
    companyId
    company {
      ...FragmentCompany
    }
    balanceDate
    bankName
    personInCharge
    otherInformation
    accounts {
      currency
      amount
    }
    createdAt
    updatedAt
  }
`;
